@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Poppins,Helvetica Neue,Arial,sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
}
